<template>
  <layout-default>
    <div class="container">
      <div class="container breadcrumb d-flex flex-column border-bottom">
        <Breadcrumb />
        <span class="single-product-title p-0 fw-bold">{{ car.name }}</span>
      </div>
      <div class="row single-product d-flex justify-content-center">
        <div class="col-md-5 col-sm-12 p-0">
          <product-slider
            v-if="car && car.imageUrl"
            class="product-image"
            :photos="[car.imageUrl]"
          ></product-slider>
        </div>

        <!-- <div
          class="
            product-details-col
            col-md-7 col-sm-12
            d-flex
            flex-column
            mt-4 mt-md-0
          "
        >
          <div v-html="car.description"></div>
        </div> -->
      </div>
      <div v-if="colorVariants.filter((a) => a.isActive && a.url && a.url.length > 0).length" class="overflow-x margin-bottom">
        <h2>Available Colors</h2>
        <div class="d-flex">
          <div class="variant-image-box" v-for="(variant, i) in colorVariants.filter((a) => a.url && a.url.length > 0)" :key="i">
            <img :src="variant && variant.url && variant.url.length > 0 ? variant.url[0] : ''" :alt="variant.typeSubCategory" srcset="" class="variant-image" :class="{'selected' : variant._id === selectedVariant._id}" @click="changeImage(variant)">
            <p class="variant-detail">{{variant.typeSubCategory}}</p>
          </div>
        </div>
      </div>
      <FormQuery
        :selectedVariant="selectedVariant"
        :car="car"
        :price="price"
        :optionsVariants="optionsVariants.filter((a) => a.isActive)"
        :colorVariants="
          colorVariants.filter((a) => a.isActive && a.url && a.url.length > 0)
        "
        @priceChange="priceUpdate"
        @updateImage="updateImage"
      />
      <!-- <div class="container tab-product p-0">
        <div class="">
          <div class="tabs">
            <tab-wrapper>
              <tab :title="'Description'"
                ><Description
                  class="mt-4"
                  :description="car.description"
              /></tab>
              <tab
                :title="'Additional Information'"
                v-if="car.type === 'auction'"
                ><AdditionalInfo class="mt-4" :items="car.specTable"
              /></tab>
              <tab
                :title="'Auction History'"
                v-if="car.type === 'auction'"
                ><TableAuctionHistory
                  class="mt-4"
                  :latestAuctions="car.history"
              /></tab>
              <tab :title="'Vendor Info'"
                ><VendorInfo class="mt-4" v-bind="car.vendorInfo"
              /></tab>
              <tab :title="'More from Vendor'">
                <div class="row mt-5">
                  <div
                    v-for="auction in getLimitedAuctions"
                    :key="auction"
                    class="col-6 col-md-3 mb-4"
                  >
                    <ProductCard
                      v-bind="auction"
                      :endDate="new Date(auction.endDate)"
                    />
                  </div></div
              ></tab>
            </tab-wrapper>
          </div>
        </div>
      </div>
      <div class="related-products">
        <span class="single-product-title fw-bold">Related Products</span>
        <div class="row mt-5">
          <div
            v-for="auction in getLimitedAuctions"
            :key="auction"
            class="col-6 col-md-3 mb-4"
          >
            <ProductCard
              v-bind="auction"
              :endDate="new Date(auction.endDate)"
            />
          </div>
        </div>
      </div> -->
    </div>
  </layout-default>
</template>

<script>
import LayoutDefault from "../../components/organisms/LayoutDefault/LayoutDefault.vue";
import TabWrapper from "../../components/molecules/Tab/TabWrapper.vue";
import Tab from "../../components/molecules/Tab/TabContent.vue";
import Counter from "../../components/atoms/Counter/Counter.vue";
import ButtonOutline from "../../components/atoms/Button/ButtonOutline.vue";
import FilterListTags from "../../components/atoms/Filter/FilterListTags.vue";
import Breadcrumb from "../../components/atoms/Breadcrumb/Breadcrumb.vue";
import TableAuctionHistory from "../../components/atoms/Product/ProductTableAuctionHistory.vue";
import VendorInfo from "../../components/atoms/Product/ProductVendorInfo.vue";
import Description from "../../components/atoms/Product/ProductDescription.vue";
import AdditionalInfo from "../../components/atoms/Product/ProductSpecifications.vue";
import ProductCard from "../../components/molecules/Product/ProductCard.vue";
import { products } from "../../data/auctions.json";
import ProductSlider from "../../components/atoms/Carousel/ProductSlider.vue";
import BidIcons from "../../components/atoms/Button/ButtonIconBids.vue";
import BasicInputNumberStep from "../../components/atoms/FormInput/BasicInputNumberStep.vue";
import { addToCart } from "../../composables/manageCart";
import { mapGetters } from "vuex";
import FormQuery from "../../components/molecules/Form/FormQuery.vue";
import axios from "axios";
import { dimensions } from "../../data/dimensions.json";
import FilterListSize from "../../components/atoms/Filter/FilterListSize.vue";
import FilterTitle from "../../components/atoms/Filter/FilterTitle.vue";
import { API_BASE_URL } from '../../config';

export default {
  setup() {
    return { addToCart };
  },
  created() {},
  components: {
    FilterListSize,
    FilterTitle,
    LayoutDefault,
    ProductSlider,
    Tab,
    TabWrapper,
    Counter,
    BidIcons,
    ButtonOutline,
    BasicInputNumberStep,
    FilterListTags,
    Breadcrumb,
    TableAuctionHistory,
    VendorInfo,
    AdditionalInfo,
    Description,
    ProductCard,
    FormQuery,
  },
  mounted() {
    this.$store.dispatch('setLoading', true)
    this.getCarById();
    document.title = this.car.name + " - NotSticker";
    this.$store.commit("setLocation", [
      { title: "Cars", location: "/shop" },
      {
        title: this.car.title,
        location: "/product/" + this.car._id,
      },
    ]);
    this.$store.dispatch('setLoading', false)
  },
  data() {
    return {
      headers: JSON.parse(localStorage.getItem("headers")),
      url: API_BASE_URL,
      val: 1,
      car: {},
      colorVariants: [],
      optionsVariants: [],
      selectedOptionsVariants: [],
      auctions: products,
      dimensions,
      selectedVariant: {},
      price: 0,
      copyOfPrice: 0,
    };
  },
  computed: {
    ...mapGetters(["carListForShopPage"]),
    getLimitedAuctions() {
      return this.auctions.slice(Math.max(this.auctions.length - 4, 0));
    },
    getProduct() {
      return this.car;
      let p = this.carListForShopPage.find(
        (product) => product._id == this.$route.params.id
      );
      console.log("car", this.car);
      console.log("p", p);
      return p;
    },
    didAuctionEnd() {
      return new Date(this.car.endDate) > new Date();
    },
    shortdesc() {
      return this.car.description.substr(
        0,
        this.car.description.indexOf("</p>") + 4
      );
    },
  },
  methods: {
    updateImage(image) {
      this.car.imageUrl = image;
    },
    priceUpdate(price) {
      console.log("priceUpdate",price)
      this.price = parseInt(this.copyOfPrice) + parseInt(price);
    },
    async changeImage(variant) {
      if (variant._id === this.selectedVariant._id) {
        this.selectedVariant = {};
        this.price = parseInt(this.price) - parseInt(variant.price);
        this.car.imageUrl = this.carCopy.imageUrl;
        return;
      }
      console.log("variant", variant);
      this.selectedVariant = variant;
      this.price = parseInt(this.price) + parseInt(variant.price);
      this.car.imageUrl = variant.url[0];
    },
    async getCarById() {
      try {
        this.$store.dispatch('setLoading', true)
        await axios
          .get(
            `${this.url}/product/getCarById/${this.$route.params.id}`,
            this.headers
          )
          .then(async (res) => {
            this.car = res.data.data;
            this.carCopy = {...this.car};
            this.price = this.car.price;
            this.copyOfPrice = this.car.price;
            document.title = this.car.name + " - NotSticker";
            if (this.car && this.car.variants.length > 0) {
              this.colorVariants = this.car.variants.filter(
                (d) => d.typeCategory === "color"
              );
              this.optionsVariants = this.car.variants.filter(
                (d) => d.typeCategory === "options"
              );
            }
            this.$store.dispatch('setLoading', false)
          });
      } catch (error) {
        this.$store.dispatch('setLoading', false)
        throw error;
      }
    },
    addToCart(data) {
      this.$store.dispatch("selectCar", data);
      document.getElementById("form-query").scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    },
  },
};
</script>
<style scoped="scoped">
:deep(.list-tags .item) {
  color: #000 !important;
  font-weight: bold !important;
}

:deep(.list-category .item) {
  color: #000 !important;
  font-weight: bold !important;
}
:deep(.single-product-counter > .Counter) {
  margin: 0;
}
.counter-bid {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100% !important;
}
.product-details-col {
  padding: 0 40px;
}
.single-product {
  padding: 50px 0;
}
.product-image {
  max-width: 600px;
  height: auto;
}
:deep(.product-image .mySwiper2) {
  box-shadow: 0px 0px 25px rgb(0 0 0 / 10%);
  margin-bottom: 15px;
}
:deep(.Counter) {
  border: 0 !important;
}
:deep(.single-product-counter .time) {
  font-size: 21px !important;
  color: #000 !important;
  font-weight: 500 !important;
}
:deep(.single-product-counter .time-type) {
  font-size: 10px !important;
  color: #000 !important;
  text-transform: uppercase;
  font-weight: 500 !important;
}
:deep(.product-counter .Counter) {
  padding: 20px 20px 15px;
}
:deep(.product-counter .col-3) {
  padding: 0 20px !important;
}

:deep(.product-counter .counter-col) {
  border: none !important;
}

.current-bid {
  font-size: 25px;
}
.counter-bid {
  padding: 0px !important;
}
.current-bid-title {
  padding: 0 0 20px !important;
}
.product-bid-icons {
  display: flex !important;
  align-items: center !important;
}
.product-bid-icons > .svg-inline--fa {
  margin: 0px !important;
  margin-left: 5px !important;
}
.product-bid-btn {
  background-color: #bababa;
  border: 2px solid var(--primary-color) !important;
  color: var(--primary-color);
  height: 40px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  display: block !important;
  font-size: 13px;
  margin-left: 15px;
}
.product-bid-btn:hover {
  background-color: var(--primary-color) !important;
  color: white;
}
.product-condition {
  margin: 15px 0;
}
.product-series,
.product-bid,
.product-auction-end {
  margin: 10px 0 0;
}
.product-auction-end {
  margin: 20px 0 0;
}
.product-bid-categories,
.product-bid-timezone {
  margin: 0 0 10px !important;
}
.product-series {
  margin: 20px 0 10px;
}
.list-category li,
.list-tags li {
  color: #000;
  font-weight: bold !important;
}
.tabs {
  padding: 50px 0 50px 0;
}
.single-product-title {
  font-size: 30px !important;
}

.breadcrumb {
  padding-top: 40px;
  padding-bottom: 40px;
}

.related-products {
  margin-top: 80px;
  margin-bottom: 80px;
}
@media (max-width: 767.98px) {
  .breadcrumb {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .product-condition {
    margin: 15px 0 0;
  }
  .bid-value {
    align-items: flex-start !important;
  }
  :deep(.bid-value .btn) {
    margin: 0px !important;
  }
  .product-details-col {
    padding: 0;
  }
  .tab-product {
    padding: 0px !important;
  }
  .product-image {
    padding: 0px !important;
  }
  :deep(.product-counter .Counter .col-3) {
    padding: 7px !important;
  }
  .current-bid-title {
    padding: 20px 0 !important;
  }
  .tabs {
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }
  .bid-value {
    display: flex;
    flex-direction: column;
  }
  .single-product {
    display: flex !important;
    flex-direction: column !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .col-md-5 {
    margin: 0 !important;
  }
  .product-img {
    width: 300px;
  }
  .product-bid {
    display: block !important;
  }
  .bid-value {
    justify-content: flex-start !important;
    display: flex !important;
    align-items: center;
    margin: 10px 0;
  }

  .bid-btn {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
  }
  .product-bid-btn {
    width: 100%;
    text-align: center !important;
  }
  .single-product-title {
    font-size: 26px !important;
  }

  .bid-icons {
    margin-left: 30px;
  }
  .product-bid-icons > .svg-inline--fa {
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .product-image,
  .product-img {
    width: 350px;
    height: 350px;
  }
  .product-bid {
    display: flex !important;
    flex-direction: column;
  }

  .bid-btn {
    width: 100%;
    justify-content: start;
  }
}
.variant-image {
  object-fit: cover;
  width: 13em;
  height: 10em;
  margin-right: 20px;
}
.margin-bottom {
  margin-bottom: 1em;
}
.variant-image-box {
  text-align: center;
  cursor: pointer;
}
.variant-detail {
  color: grey;
  text-transform: capitalize;
}
.selected {
  box-shadow: 0px 12px 22px 1px lightgrey;
}
.overflow-x{
  overflow: auto;
  white-space: nowrap;
}
</style>
