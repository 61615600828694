<template>
  <div class="query-form">
    <form id="form-query">
      <div class="contact-form mb-2">
        <div class="form-title d-flex flex-column">
          <span class="fw-bold fs-1"
            >What options would you like?</span
          >
          <div class="d-flex margin-bottom gap-15px options-list">
            <div
              class="variant-image-box"
              v-for="(variant, i) in optionsVariants"
              :key="i"
            >
              <input
                type="checkbox"
                :id="variant.typeSubCategory"
                :value="variant"
                v-model="selectedOptionsVariants"
              />
              <label class="variant-detail" :for="variant.typeSubCategory">{{
                variant.typeSubCategory
              }}</label>
            </div>
          </div>
          <span class="subtitle fw-light fs-6 text-secondary"
            >Your Car's MSRP :
            <span class="bold">
              {{ $formatPrice(price || car.price) }}</span
            ></span
          >
          <div class="user-details justify-content-between d-flex me-5">
            <div class="col-md-6 col-input pe-3">
              <basic-input-error>
                <BasicInputText
                  v-model="color"
                  :placeholder="'Your Choice of color'"
                  required
                  :hasFocus="false"
                />
              </basic-input-error>
            </div>
            <div class="col-md-6 col-input ps-3">
              <basic-input-error>
                <BasicInputText
                  type="number"
                  v-model="bidPrice"
                  :placeholder="'Drive Your Price'"
                  required
                  :hasFocus="false"
                />
              </basic-input-error>
            </div>
          </div>

          <div class="user-message">
            <basic-input-error :error="textAreaError">
              <BasicTextArea
                class="fw-light user-textarea"
                :placeholder="'Other comments to our dealer network'"
                v-model="details"
              />
            </basic-input-error>
          </div>
          <div class="mt-4">
            <button-filled
              @click.prevent="submitBid()"
              class="send-btn text-white"
              :size="'small'"
              :text="'Send Message'"
              :hasRoundedCorners="true"
            >
              <template v-slot:default>
                <span class="me-3">Submit Your Bid</span
                ><font-awesome-icon
                  class="fa"
                  :icon="['fas', 'long-arrow-alt-right']"
                />
              </template>
            </button-filled>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import BasicInputText from "../../atoms/FormInput/BasicInputText.vue";
import BasicInputError from "../../atoms/FormInput/BasicInputError.vue";
import BasicTextArea from "../../atoms/FormInput/BasicTextArea.vue";
import ButtonFilled from "../../atoms/Button/ButtonFilled.vue";
import validateContact from "./validations/validatorContact.js";
import { API_BASE_URL } from '../../../config';
import axios from "axios";
import Swal from "sweetalert2";

import { mapGetters } from "vuex";

export default {
  setup() {
    return validateContact();
  },
  mounted() {
    console.log("mounted form query");
    // this.getCarById()
  },
  data() {
    return {
      headers: JSON.parse(localStorage.getItem("headers")),
      url: API_BASE_URL,
      car: {},
      color: "",
      bidPrice: null,
      details: "",
      selectedOptionsVariants: [],
      selectedColor: {},
      carImage: "",
    };
  },
  props: {
    selectedVariant: {
      type: Object,
    },
    car: {
      type: Object,
    },
    optionsVariants: {
      type: Array,
    },
    colorVariants: {
      type: Array,
    },
    price: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters(["selectedCar", "user"]),
  },
  components: {
    BasicInputText,
    BasicTextArea,
    ButtonFilled,
    BasicInputError,
  },
  methods: {
    async changeImage(variant) {
      console.log("variant", variant);
      this.selectedColor = variant;
    },
    async getCarById() {
      try {
        await axios
          .get(
            `${this.url}/product/getCarById/${this.$route.params.id}`,
            this.headers
          )
          .then(async (res) => {
            this.car = res.data.data;
            document.title = this.car.name + " - NotSticker";
          });
      } catch (error) {
        throw error;
      }
    },
    async submitBid() {
      if (!this.color.trim() || !this.bidPrice.trim()) {
        Swal.fire({
          title: "Error!",
          text: "Please enter all fields",
          icon: "error",
          confirmButtonText: "Cool",
        });
        return;
      }
      await this.getCarById();
      let variants = [];
      if (this.selectedOptionsVariants.length) {
        variants = this.selectedOptionsVariants.map((data) => data._id);
      }
      if (this.selectedVariant && this.selectedVariant._id) {
        variants.push(this.selectedVariant._id);
      }
      let data = {
        carMaker: this.car.company.name,
        carName: this.car.name,
        // carImage: this.selectedVariant && this.selectedVariant.url && this.selectedVariant.url[0] ? this.selectedVariant.url[0] : this.car.imageUrl,
        carImage: this.carImage ? this.carImage : this.car.imageUrl,
        carMsrp: this.price.toString(),
        userComments: this.details,
        carBid: this.bidPrice,
        carColor: this.color,
        userName: this.user.fullName,
        userEmail: this.user.email,
        userId: this.user._id,
        carId: this.car._id,
        variants: variants,
      };
      console.log("data", data);
      this.$store.dispatch("submitBid", data);
    },
  },
  watch: {
    selectedVariant: {
      immediate: true,
      handler(value) {
        if (value && value.typeSubCategory && value.price) {
          console.log(
            value.typeSubCategory + " - " + this.$formatPrice(value.price)
          );
          this.color =
            value.typeSubCategory + " - " + this.$formatPrice(value.price);

          let optionsTotal =
            this.selectedOptionsVariants
              .map((v) => {
                return parseInt(v.price);
              })
              .reduce((partialSum, a) => partialSum + a, 0);
          // this.carImage = value.url[0];
          let colorTotal = parseInt(value.price);
          console.log("optionsTotal", optionsTotal);
          console.log("colorTotal", colorTotal);
          console.log("priceUpdate", optionsTotal + colorTotal);
          this.$emit("priceUpdate", optionsTotal + colorTotal);
        }
      },
    },
    selectedOptionsVariants: {
      immediate: true,
      deep: true,
      handler(val) {
        let optionsTotal = val
          .map((v) => {
            return parseInt(v.price);
          })
          .reduce((partialSum, a) => partialSum + a, 0);
        console.log("optionsTotal", optionsTotal);
        let colorPrice = parseInt(this.selectedVariant.price || 0);
        console.log("colorPrice",colorPrice)
        this.$emit("priceChange", optionsTotal + colorPrice);
      },
    },
    price: {
      immediate: true,
      deep: true,
      handler(val) {
        // let newPrice = this.selectedOptionsVariants
        //   .map((v) => {
        //     return parseInt(v.price);
        //   })
        //   .reduce((partialSum, a) => partialSum + a, 0);
        // console.log("newPrice", newPrice);
        // this.$emit("priceChange", newPrice);
      },
    },
  },
};
</script>

<style scoped="scoped">
.subtitle {
  margin-bottom: 30px;
}
.contact-form {
  padding-top: 60px !important;
  padding-right: 30px;
  padding-bottom: 85px;
  padding-left: 160px;
  box-shadow: 0px 0px 25px rgb(0 0 0 / 10%);
  min-height: 500px;
}
.form-control {
  background-color: #efefef !important;
  box-shadow: none;
  border-radius: 10px 10px 10px 10px;
}
.send-btn {
  height: 40px;
  padding: 0 20px !important;
}
.user-details,
.user-textarea {
  width: 100%;
}
.user-details {
  margin-bottom: 40px;
}
.user-details {
  border: none !important;
}

@media (max-width: 767.98px) {
  .contact-form,
  .user-details {
    display: flex !important;
    flex-direction: column;
  }
  .col-input {
    padding: 0 !important;
    margin-top: 20px;
  }
  .contact-form {
    width: 100%;
    display: flex;
    padding: 30px;
  }
  .user-details > input {
    width: 290px !important;
    height: 50px;
    border-radius: 10px !important;
    border: none;
  }
  .user-textarea {
    width: 290px;
    border: none;
  }
  .user-details {
    margin-bottom: 20px;
  }
}
.mb-2 {
  margin-bottom: 2em !important;
}
.bold {
  font-weight: 600;
  font-size: 1.25em;
}
.variant-detail {
  color: grey;
  text-transform: capitalize;
  margin-left: 5px;
}
.gap-15px {
  display: flex;
  gap: 15px;
}
.margin-bottom {
  margin: 1em 0em;
}
.variant-image {
  object-fit: cover;
  width: 13em;
  height: 10em;
  margin-right: 20px;
}
.margin-bottom {
  margin-bottom: 1em;
}
.variant-image-box {
  text-align: center;
  cursor: pointer;
}
.variant-detail {
  color: grey;
  text-transform: capitalize;
}
.selected {
  box-shadow: 0px 12px 22px 1px lightgrey;
}
.options-list {
  flex-wrap: wrap;
  justify-content: flex-start;
}
.query-form {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1em;
    justify-content: center;
}
</style>
